<template>
  <div class="homePage">
    <div style="height: 300px;width: 100%;"></div>
    <div class="titleRight">
      <img :src="titleImg">
    </div>
    <div class="contant">
      <div class="spantitle">
        <span class="img_p">
          报告
        </span>
        <span>{{objAll.title}}</span>
        <span>{{objAll.times}}</span>
      </div>
      <div>
        <img class="imgCont" :src="homeage" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objAll: {
      type: Object,
      default: function () {
        return { title: '', times: '' }
      }
    }
  },
  components: {},
  data() {
    return {
      titleImg: require('../../assets/imgs/logo-color2.png'),
      homeage: require('../../assets/imgs/login/homeage.png')
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.homePage {
  width: 100%;
  // height: 100vw;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 40px;
  box-sizing: border-box;
  margin-bottom: 100px;

  .titleRight {
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: flex-end;

    &>img {
      width: 88px;
      height: 100%;
      display: flex;
    }
  }

  .contant {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 200px;

    .spantitle {
      color: #000;
      font-size: 52px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-items: flex-start;

      &>span {
        margin: 20px 0;
      }

      &>span:nth-child(2) {
        font-weight: bold;
        font-size: 72px;
      }

      .img_p {
        display: block;
        border-top: 6px solid red;
        padding-top: 16px;
        width: 128px;
      }
    }

    .imgCont {
      // width: 793px;
      width: 100%;
      display: block;
      margin: auto;
    }
  }
}
</style>